import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Hidden, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';

// Config
import { styleConfig } from '../../config/config';

const useStyles = makeStyles((theme) => ({
  root: {},
  mobileRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  searchMobileRoot: {
    left: 0,
    width: '100%',
    bottom: -70,
    position: 'absolute',
    background: styleConfig.color.bg.darkergrey,
    height: 70,
    zIndex: 50,
  },
  thumbnailRoot: {
    minHeight: 45,
    position: 'relative',
  },
  thumbnail: {
    width: 45,
    minHeight: 45,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    color: styleConfig.color.text.white,
    '& svg': {
      fontSize: 34,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 15,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const SearchBar = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    {
      allWordpressWpBrand(filter: { slug: { ne: "demo" }, acf: { brand_page: { eq: true } } }) {
        edges {
          node {
            brand_meta_fields {
              brand_title
              slug
              hasChildren
            }
            acf {
              slug_override
              lease_builder
              brand_page
              menu_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      width: 45
                      height: 45
                      layout: FIXED
                    )
                  }
                }
              }
            }
            slug
            wordpress_id
            wordpress_parent
            parent_element {
              brand_meta_fields {
                slug
              }
              slug
              wordpress_parent
              parent_element {
                brand_meta_fields {
                  slug
                }
                slug
                wordpress_parent
                parent_element {
                  brand_meta_fields {
                    slug
                  }
                  slug
                }
              }
            }
          }
        }
      }
    }
  `);

  const [carData, setCarData] = useState();
  const [isSearchMenu, setIsSearchMenu] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Create the options array based on the pageContext data
    const searchData = data.allWordpressWpBrand.edges.map(
      (item) => {
        // Build the slug up based on the parent object and the custom slug
        let slug;

        if (
          item.node &&
          item.node.parent_element &&
          item.node.parent_element.parent_element &&
          item.node.parent_element.parent_element.brand_meta_fields
        ) {
          slug = `/${item.node.parent_element.parent_element.brand_meta_fields.slug}/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`;
        } else if (
          item.node.parent_element &&
          item.node.parent_element.brand_meta_fields &&
          item.node.parent_element.brand_meta_fields.slug
        ) {
          slug = `/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`;
        } else {
          slug = `/${item.node.brand_meta_fields.slug}/`;
        }

        // Build up the data for the search menu from the brand data
        return {
          value: item.node.slug,
          label: item.node.brand_meta_fields.brand_title,
          slug,
          thumbnail: get(item, 'node.acf.menu_image.localFile.childImageSharp.fixed'),
        };
      },
      [data.allWordpressWpBrand]
    );

    // Sort the results into alphabetical order based on the title
    const sortedData = searchData.sort((a, b) => a.label.localeCompare(b.label));

    setCarData(sortedData);
    setIsLoading(false);
  }, [data.allWordpressWpBrand]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      height: isSearchMenu && 75,
      border: isSearchMenu && 'none',
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderWidth: 2,
      borderColor: styleConfig.color.bg.darkgrey,
      color: styleConfig.color.bg.white,
      outline: 0,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'inherit',
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: styleConfig.color.text.lightgrey,
      paddingLeft: 25,
      fontSize: 13,
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: 15,
      position: 'relative',
    }),
    singleValue: (styles) => ({
      ...styles,
      paddingLeft: 25,
      fontSize: 13,
      maxWidth: '80%',
    }),

    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      marginTop: isSearchMenu && -5,
      zIndex: 9999,
    }),

    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      zIndex: 9999,
    }),

    input: (styles) => ({
      ...styles,
      color: styleConfig.color.text.white,
      '&:focus': {
        outline: 0,
      },
    }),

    groupHeading: (styles) => ({
      ...styles,
    }),

    option: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      borderBottom: '1px #DDD solid',
      cursor: 'pointer!important',
      fontSize: 11.5,
      fontWeight: 800,
      minHeight: 64,
      outline: 0,
      boxShadow: 'none',
      color:
        !isDisabled &&
        (isSelected || isFocused ? styleConfig.color.bg.white : styleConfig.color.bg.darkgrey),

      backgroundColor:
        !isDisabled &&
        (isSelected || isFocused ? styleConfig.color.bg.gold : styleConfig.color.bg.white),
      opacity: isFocused ? 1 : isSelected ? 0.5 : 1,

      ':active': {
        ...styles[':active'],
        backgroundColor: styleConfig.color.bg.gold,
        color: styleConfig.color.bg.white,
        fontSize: 12,
      },

      ':hover': {
        ...styles,
        backgroundColor: styleConfig.color.bg.gold,
        color: styleConfig.color.text.white,
        fontSize: 12,

        ':active': {
          backgroundColor: styleConfig.color.bg.gold,
          color: styleConfig.color.text.white,
        },
      },
    }),
  };

  const handleInputChange = (option) => {
    navigate(option.slug);
  };

  const customFilterOption = (option, rawInput) => {
    const words = rawInput.split(' ');
    const optionStrings = option.label.toLowerCase().split(' ');

    // Accept a minimum of 2 characters
    if (rawInput.length < 2) {
      return null;
    }

    // Check for a word match for low character counts to cover common brands/models
    if (rawInput.length < 4 && optionStrings.length) {
      const matches = optionStrings.filter(
        (option) => option.substring(0, rawInput.length) === rawInput
      );
      return matches.length >= 1;
    }

    // Fallback on the standard search for more specific searches
    return words.reduce(
      (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  };

  const Option = (props: OptionProps) => {
    return (
      <components.Option {...props}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{props.data.label}</Grid>
            <div className={classes.thumbnailRoot}>
              {props.data.thumbnail && (
                <Grid>
                  <div className={classes.thumbnail}>
                    <GatsbyImage alt="" loading="eager" image={props.data.thumbnail} />
                  </div>
                </Grid>
              )}
            </div>
          </Grid>
        </Box>
      </components.Option>
    );
  };

  const ValueContainer = ({ children, ...props }) => (
    <components.ValueContainer {...props}>
      <SearchIcon />
      {children}
      <CloseIcon className={classes.closeIcon} />
    </components.ValueContainer>
  );

  const CustomInput = ({ ...props }) => (
    <label>
      <components.Input {...props} aria-label={props.id} />
    </label>
  );

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Select
          filterOption={customFilterOption}
          isLoading={isLoading}
          components={{
            Option,
            ValueContainer,
            Input: CustomInput,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable
          isMenuOpen
          onChange={handleInputChange}
          openMenuOnClick={false}
          options={carData}
          placeholder="Quick Search: e.g. BMW X1"
          styles={colourStyles}
          noOptionsMessage={({ inputValue }) => {
            if (inputValue.length <= 1) {
              return null;
            }

            return 'No results found';
          }}
        />
      </Hidden>

      <Hidden lgUp>
        <div className={classes.mobileRoot}>
          <Button
            className={classes.buttonIcon}
            aria-label="Open search menu"
            variant="text"
            name="open-search-menu"
            onClick={() => setIsSearchMenu(!isSearchMenu)}
          >
            <SearchIcon />
          </Button>

          {isSearchMenu && (
            <div className={classes.searchMobileRoot}>
              <Select
                filterOption={customFilterOption}
                autoFocus
                isLoading={isLoading}
                components={{
                  Option,
                  ValueContainer,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isSearchable
                onChange={handleInputChange}
                openMenuOnClick={false}
                options={carData}
                placeholder="Quick Search: e.g. BMW X1"
                styles={colourStyles}
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue.length <= 1) {
                    return null;
                  }

                  return 'No results found';
                }}
              />
            </div>
          )}
        </div>
      </Hidden>
    </div>
  );
};

interface OptionProps {
  data: {
    value: string;
    label: string;
    slug: string;
    thumbnail: any;
  };
}

export default SearchBar;
