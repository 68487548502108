import React from 'react';
import { Hidden, Container, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { rgba } from 'polished';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { styleConfig } from '../../config/config';

import RouteLink from './RouteLink';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: '#2B2B2B',
    padding: '100px 0',
    color: '#FFF',
  },
  logo: {
    width: 300,
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      width: 175,
      marginBottom: 15,
    },
  },
  title: {
    color: styleConfig.color.text.white,
    fontFamily: styleConfig.fonts.header,
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 25,
    textTransform: 'uppercase',
  },
  menu: {
    listStyle: 'none',
    padding: 0,
    margin: '0 0 50px 0',

    '& > li': {
      color: rgba('#FFFFFF', 0.6),
      fontSize: 15,
      marginBottom: 15,

      '& > a': {
        transition: '333ms ease-in-out all',
        '&:hover': {
          color: '#FFFFFF',
        },
      },
    },
  },
  footerPrimary: {
    marginBottom: 45,
    borderBottom: `1px ${rgba('#FFFFFF', 0.1)} solid`,
  },
  footerSecondary: {
    color: rgba('#FFFFFF', 0.1),
    '& p': {
      fontSize: 14,
      marginBottom: 5,
    },
    '& a': {
      color: '#FFF',
      textDecoration: 'underline',
    },
  },
  footerSecondaryTitle: {
    color: styleConfig.color.text.white,
    fontWeight: 600,
    marginBottom: 10,
  },
  bvrlaLogo: {
    margin: '30px 0 15px',
  },
  bvrlaNumber: {
    marginBottom: 15,
  },
  copyright: {
    fontSize: 15,
    fontWeight: 600,
    color: styleConfig.color.text.white,
    marginTop: 15,
    '& a': {
      textDecoration: 'underline',
    },
  },
}));

const Footer: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      bvrla: file(relativePath: { eq: "bvrla.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, width: 100, height: 39, layout: FIXED)
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, width: 182, height: 70, layout: FIXED)
        }
      }
      cars: wordpressWpApiMenusMenusItems(name: { eq: "Footer (1)" }) {
        items {
          wordpress_id
          title
          object_slug
          url
          type
        }
      }
      info: wordpressWpApiMenusMenusItems(name: { eq: "Footer (2)" }) {
        items {
          wordpress_id
          title
          object_slug
          url
          type
        }
      }
      terms: wordpressWpApiMenusMenusItems(name: { eq: "Footer (3)" }) {
        items {
          wordpress_id
          title
          object_slug
          url
          type_label
          type
        }
      }
    }
  `);

  const classes = useStyles();

  return (
    <footer className={classes.wrapper} key="footer-root">
      <Container maxWidth="xl">
        <Box className={classes.footerPrimary}>
          <Grid container spacing={3}>
            <Hidden mdDown>
              <Grid item xl={2} lg={3} md={6} sm={12}>
                <GatsbyImage
                  image={data.logo.childImageSharp.gatsbyImageData}
                  className={classes.logo}
                  alt="Morgan Highfield &mp; Land"
                  loading="lazy"
                />
              </Grid>
            </Hidden>

            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <Typography className={classes.title} variant="h6" component="p">
                Brands We Lease
              </Typography>

              <ul className={classes.menu}>
                {data.cars.items.map((item, index) => {
                  return (
                    <li key={item.wordpress_id}>
                      <RouteLink
                        aria-label={item.title}
                        href={item.url}
                        key={`footer-menu1-title${index}`}
                      >
                        {item.title}
                      </RouteLink>
                    </li>
                  );
                })}
              </ul>
            </Grid>

            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <Typography className={classes.title} variant="h6" component="p">
                Information
              </Typography>

              <ul className={classes.menu}>
                {data.info.items.map((item, index) => {
                  return (
                    <li key={item.wordpress_id}>
                      <RouteLink
                        href={item.url}
                        aria-label={item.title}
                        key={`footer-menu2-title${index}`}
                      >
                        {item.title}
                      </RouteLink>
                    </li>
                  );
                })}
              </ul>
            </Grid>

            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <Typography className={classes.title} variant="h6" component="p">
                Terms
              </Typography>

              <ul className={classes.menu}>
                {data.terms.items.map((item, index) => {
                  return (
                    <li key={item.wordpress_id}>
                      <RouteLink
                        href={item.url}
                        aria-label={item.title}
                        key={`footer-menu3-title${index}`}
                      >
                        {item.title}
                      </RouteLink>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.footerSecondary}>
          <Grid container>
            <Grid item md={12}>
              <React.Fragment>
                <Typography className={classes.footerSecondaryTitle}>
                  Regulatory Information
                </Typography>

                <Typography>
                  Morgan Highfield &amp; Land and Prestige Car Leasing are trading names of
                  Yorkshire Vehicle Finance Limited who are a credit broker, not a Lender. Yorkshire
                  Vehicle Finance Limited is Authorised and Regulated by the Financial Conduct
                  Authority - FRN 665369.
                </Typography>

                <Typography>
                  Morgan Highfield &amp; Land is not an independent financial advisor and does not
                  offer qualified tax advice. Any general information found on this website or
                  otherwise is no substitute for your own qualified tax or legal advice. Lenders may
                  pay us for introducing you to them.
                </Typography>

                <GatsbyImage
                  image={data.bvrla.childImageSharp.gatsbyImageData}
                  alt="BBRLA"
                  className={classes.bvrlaLogo}
                  loading="lazy"
                />

                <Typography className={classes.bvrlaNumber}>
                  BVRLA membership number – 0893
                </Typography>

                <Typography>
                  Contract Hire and all other methods of funding are subject to status, guarantees
                  may be required. Different terms, mileages and contracts are available upon
                  request.
                </Typography>

                <Typography>
                  By continuing to browse this site, you give consent for cookies to be used. For
                  more details please read our{' '}
                  <Link to="/privacy-cookie-policy/">Privacy &amp; Cookie policy</Link>.
                </Typography>

                <Typography className={classes.copyright}>
                  © {new Date().getFullYear()} Morgan Highfield and Land
                </Typography>
              </React.Fragment>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
