import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconArrowForward from '@material-ui/icons/ArrowForward';

const MuiButton: React.FunctionComponent<MuiButtonProps> = (props) => {
  const {
    isIcon,
    margin,
    labelColor,
    borderColor,
    minWidth,
    height,
    fontWeight,
    textColor,
    ...rest
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    button: {
      color: textColor ? textColor : theme.palette.common.black,
      fontWeight: fontWeight || 600,
      letterSpacing: 1,
      borderRadius: 0,
      height: height || '50px',
      minHeight: height || '50px',
      minWidth: minWidth || 200,
      padding: '0 25px',
      boxShadow: 'none',
      fontFamily: 'Open Sans',
      borderColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: margin || 0,

      [theme.breakpoints.down('sm')]: {
        marginBottom: margin || 25,
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    contained: {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
    },
    outlined: {},
    labelColor: {
      color: labelColor,
    },
    borderColor: {
      borderColor: borderColor,
      '&:hover': {
        borderColor: borderColor,
      },
    },
    icon: {
      marginLeft: 15,
    },
    disabled: {},
    disabledPrimary: {
      backgroundColor: `${theme.palette.primary.main}!important`,
      opacity: 0.5,
      color: '#FFF!important',
    },
  }));

  const classes = useStyles();

  return (
    <Button
      className={clsx([
        classes.button,
        props.variant && classes[props.variant],
        labelColor && classes.labelColor,
        borderColor && classes.borderColor,
      ])}
      classes={{
        disabled: props.variant === 'contained' ? classes.disabledPrimary : classes.disabled,
      }}
      type="button"
      color="primary"
      {...rest}
    >
      {props.children}
      {isIcon && <IconArrowForward className={classes.icon} />}
    </Button>
  );
};

interface MuiButtonProps extends ButtonProps {
  margin?: string;
  borderColor?: string;
  textColor?: string;
  fontWeight?: number;
  height?: number;
  minWidth?: number;
  isIcon?: boolean;
  labelColor?: string;
}

export default MuiButton;
