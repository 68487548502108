import React from 'react';
import { Helmet } from 'react-helmet';
import { lighten } from 'polished';
import { Box, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { styleConfig } from '../../config/config';

const MobileMenuBrands: React.FunctionComponent<MobileMenuBrandsProps> = (props) => {
  const { isOpen } = props;

  const useStyles = makeStyles(() => ({
    root: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      background: lighten(0.075, styleConfig.color.bg.black),
      top: 0,
      zIndex: 970,
      borderTop: '1px #444 solid',
      paddingTop: 102,
    },

    menuRoot: {
      margin: '50px 0',
      padding: 0,
      listStyle: 'none',
      minHeight: 500,
      fontFamily: styleConfig.fonts.header,
      position: 'relative',

      '& > li': {
        fontWeight: 600,
        display: 'block',
        color: '#FFF',
        transition: '333ms ease-in-out all',
        position: 'relative',

        '& .anchor': {
          '& svg': {
            opacity: 0,
            transform: 'translate3d(-50px, 0, 0)',
            willChange: 'transform',
          },

          '&:hover': {
            '& svg': {
              opacity: 1,
              fill: styleConfig.color.text.white,
            },
          },
        },

        '&.active': {
          color: styleConfig.color.text.gold,

          '&:before': {
            position: 'absolute',
            top: -50,
            right: 0,
            content: "''",
            height: 150,
            width: 30,
            zIndex: 900,
          },
        },

        '& svg': {
          transform: 'translate3d(-50px, 0, 0)',
          willChange: 'transform',
          transition: '0.3s ease-in-out transform',
        },

        '&:hover': {
          color: styleConfig.color.text.white,
          backgroundColor: styleConfig.color.bg.gold,

          '& svg': {
            opacity: 1,
            fill: styleConfig.color.text.white,
          },
        },
        '& > a': {
          width: '100%',
          padding: '22.5px 15px 22.5px 40px',
          fontSize: 21,
          display: 'block',
        },
      },
    },

    arrow: {
      verticalAlign: 'middle',
      marginLeft: 5,
      float: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class: isOpen ? 'locked' : 'unlocked',
        }}
      />

      <Fade in={isOpen} timeout={{ enter: 350, exit: 200 }}>
        <Box className={clsx('mobile-menu', classes.root)}>{props.children}</Box>
      </Fade>
    </React.Fragment>
  );
};

interface MobileMenuBrandsProps {
  isOpen: boolean;
  handleMenu: Function;
}

export default MobileMenuBrands;
