import React from 'react';
import { Link } from 'gatsby';

// Functions
import { getRelativeURL, isInternalUrl } from '../utils/utils';

const RouteLink: React.FunctionComponent<RouteLinkProps> = (props) => {
  const { href, children, className, ...rest } = props;

  const hasProtocol = href.includes('http') || href.includes('https');
  const hasAnchor = href.includes('#');
  const relativeUrl = getRelativeURL(href);
  const hasSlash = href.startsWith('/');
  const isInternal = isInternalUrl(href);

  // Check if it is a relative URL by looking at the protocol
  if (!hasProtocol) {
    // If it is an anchor link treat it like a normal link so it does not behave differently
    if (hasAnchor) {
      return (
        <a className={className} href={href} {...rest}>
          {children}
        </a>
      );
    }

    // If it is not a real relative link than do nothing
    if (!hasSlash) {
      return null;
    }

    // Otherwise use client side routing
    return (
      <Link className={className} to={relativeUrl} {...rest}>
        {children}
      </Link>
    );
  }

  if (isInternal) {
    // Otherwise use client side routing
    return (
      <Link className={className} to={relativeUrl} {...rest}>
        {children}
      </Link>
    );
  }

  // Otherwise it is an external link
  return (
    <a className={className} target="_blank" rel="noopener noreferrer" href={href} {...rest}>
      {children}
    </a>
  );
};

interface RouteLinkProps {
  href: string;
  children: React.ReactNode;
  className?: any;
}

export default RouteLink;
