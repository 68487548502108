import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        titleTemplate
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }

    wordpressSiteMetadata {
      defaultTitle: name
      defaultDescription: description
    }
  }
`;

const SEO = ({
  title,
  description,
  image,
  pathname,
  article,
  ogDesc,
  ogImg,
  ogTitle,
  twitterDesc,
  twitterImg,
  twitterTitle,
  isNoIndex,
  structuredData,
}) => (
  <StaticQuery
    query={query}
    render={({
      wordpressSiteMetadata: { defaultTitle, defaultDescription },
      site: {
        siteMetadata: { titleTemplate, siteUrl, defaultImage, twitterUsername },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
        ogTitle: ogTitle || title || defaultTitle,
        ogDesc: ogDesc || description || defaultDescription,
        ogImg: ogImg || `${siteUrl}${image || defaultImage}`,
        twitterTitle: twitterTitle || title || defaultTitle,
        twitterDesc: twitterDesc || description || defaultDescription,
        twitterImg: twitterImg || `${siteUrl}${image || defaultImage}`,
      };

      return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          {isNoIndex && <meta name="robots" content="noindex,nofollow" />}

          {/* Open Graph */}
          {seo.url && <meta property="og:url" content={seo.url} />}
          {(article ? true : null) && <meta property="og:type" content="article" />}
          {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
          {seo.ogDesc && <meta property="og:description" content={seo.ogDesc} />}
          {seo.ogImg && <meta property="og:image" content={seo.ogImg} />}

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
          {seo.twitterTitle && <meta name="twitter:title" content={seo.twitterTitle} />}
          {seo.twitterDesc && <meta name="twitter:description" content={seo.twitterDesc} />}
          {seo.twitterImg && <meta name="twitter:image" content={seo.twitterImg} />}

          {structuredData && (
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
          )}
          <html lang="en" />
        </Helmet>
      );
    }}
  />
);

export default SEO;

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  ogDesc: null,
  ogTitle: null,
  ogImg: null,
  twitterDesc: null,
  twitterImg: null,
  twitterTitle: null,
  isNoIndex: false,
  structuredData: null,
};

SEO.propTypes = {
  children: PropTypes.node,
  brandTitle: PropTypes.string,
  structuredData: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  ogDesc: PropTypes.string,
  ogImg: PropTypes.string,
  ogTitle: PropTypes.string,
  twitterDesc: PropTypes.string,
  twitterImg: PropTypes.string,
  twitterTitle: PropTypes.string,
  isNoIndex: PropTypes.bool,
};
