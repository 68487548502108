import React, { useState } from 'react';
import { Grid, Hidden, Box, Fade, Button, Typography, Slide, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rgba, darken } from 'polished';
import { useStaticQuery, graphql, Link } from 'gatsby';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import KeyboarArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import uniqueId from 'lodash/uniqueId';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';

import { styleConfig } from '../../config/config';
import siteConfig from '../../config/site';

import MobileMenu from './MobileMenu';
import MobileMenuBrands from './MobileMenuBrands';
import SearchBar from './SearchBar';

let debounceTimeout = 350;
let iOSDevice;

if (typeof window !== 'undefined') {
  iOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

const Header = (props) => {
  const { searchData } = props;

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      placeholder: file(relativePath: { eq: "car-placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            width: 500
            height: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
          )
        }
      }
      manufacturers: allWordpressWpBrand(
        filter: {
          wordpress_parent: { eq: 0 }
          acf: { brand_page: { eq: true } }
          slug: { nin: ["demo"] }
        }
        sort: { fields: name, order: DESC }
      ) {
        edges {
          node {
            id
            name
            wordpress_id
            wordpress_parent
            brand_meta_fields {
              slug
              hasChildren
              hasParent
            }
            acf {
              menu_title
              menu_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                  }
                }
              }
            }
          }
        }
      }
      submenu: allWordpressWpBrand(
        sort: { fields: slug, order: ASC }
        filter: { wordpress_parent: { ne: 0 }, slug: { nin: "demo" } }
      ) {
        edges {
          node {
            id
            name
            wordpress_parent
            brand_meta_fields {
              slug
              hasChildren
              hasParent
            }
            parent_element {
              name
              brand_meta_fields {
                slug
                hasChildren
                hasParent
              }
              parent_element {
                name
                brand_meta_fields {
                  slug
                  hasChildren
                  hasParent
                }
                acf {
                  menu_title
                  menu_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 80
                          width: 600
                          height: 600
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
            }
            wordpress_id
            acf {
              brand_page
              menu_title
              menu_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      width: 600
                      height: 600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
      secondaryNav: wordpressWpApiMenusMenusItems(name: { eq: "Secondary" }) {
        items {
          wordpress_id
          title
          object_slug
          attr
        }
      }
    }
  `);

  const placeholder = data.placeholder.childImageSharp.gatsbyImageData;

  // Hooks
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileBrandMenuOpen, setIsMobileBrandMenuOpen] = useState(false);
  const [mobileStep, setMobileStep] = useState(1);
  const [activeManufacturer, setActiveManufacturer] = useState([]);
  const [activeModel, setActiveModel] = useState([]);
  const [activeRange, setActiveRange] = useState([]);
  const [modelItems, setModelItems] = useState([]);
  const [rangeItems, setRangeItems] = useState([]);
  const [modelImage, setModelImage] = useState(placeholder);

  // Needed when not using typescript
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    wrapper: {
      backgroundColor: '#000000',
      padding: '15px 0',
      position: 'relative',
      zIndex: 980,

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        position: 'fixed',
        width: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '5px 0',
        height: 85,
      },
    },
    megaMenuRoot: {
      top: 105,
      position: 'absolute',
      backgroundColor: '#181818',
      zIndex: 300,
      width: '100%',
      height: 852,
      overflow: 'hidden',

      [theme.breakpoints.down('lg')]: {
        minHeight: 502,
        overflow: 'hidden',
        maxHeight: 'calc(90vh - 105px)',
      },
    },
    logo: {
      margin: 0,
      maxWidth: 'none',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 135,
      },
    },

    headerLeft: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
      minHeight: 75,

      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
      },
    },

    headerRight: {
      paddingRight: 20,
      maxWidth: 420,
      margin: '0 auto',

      [theme.breakpoints.down('md')]: {
        paddingRight: 10,
      },
    },

    closeIcon: {
      fontSize: 32,
      color: '#FFF',
    },

    mobileHeaderRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    mobileMenuBrand: {
      padding: '25px 50px',
      overflowY: 'auto',
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },

      '& li': {
        display: 'flex',
        padding: '15px 10px 15px 0',
        borderBottom: '1px #262626 solid',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {},

        '& > a, > span': {
          width: '100%',
          display: 'flex',
          fontSize: 22,
          color: '#FFF',

          [theme.breakpoints.down('sm')]: {
            fontSize: 18,
          },

          ' & > svg': {
            verticalAlign: 'middle',
            marginLeft: 15,
          },
        },

        '& > span': {
          color: '#888',
        },
      },
    },

    mobileChevron: {
      borderLeft: '1px #444 solid',
      borderRadius: 0,
      lineHeight: 1,
      padding: 0,
      height: '100%',
    },

    mobileMenuTitle: {
      color: '#444',
      fontSize: 20,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginBottom: 10,
    },

    mobileBackBtn: {
      color: '#444',
      fontSize: 20,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginBottom: 10,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& svg': {
        transform: 'rotate(180deg)',
        verticalAlign: 'middle',
        marginRight: 15,
        marginLeft: -15,
      },
    },

    mobileButton: {
      color: '#FFF',
      fontSize: 18,
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },

      '&.primary': {
        color: styleConfig.color.text.gold,
      },
    },

    menuRoot: {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      margin: 0,

      '& > li': {
        marginRight: 15,
        fontSize: 18,
        fontWeight: 500,
        color: styleConfig.color.text.white,
        paddingRight: 15,
        cursor: 'pointer',
        transition: '333ms ease-in-out all',
        marginBottom: 0,
        borderRight: '1px #404040 solid',
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          paddingRight: 5,
        },

        '&.primary': {
          color: styleConfig.color.text.gold,
          fontWeight: 600,
        },

        '&:hover': {
          color: styleConfig.color.text.gold,

          '&.primary': {
            color: darken(0.1, styleConfig.color.text.gold),
          },
        },

        '&:last-of-type': {
          marginRight: 0,
          border: 0,
        },
      },
    },

    arrow: {
      verticalAlign: 'middle',
      marginLeft: 5,
      float: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    menuSecondary: {
      justifyContent: 'flex-end',

      '& > li': {
        color: rgba('#FFFFFF', 0.6),
        marginRight: 25,
        paddingRight: 25,
        fontSize: 16,
      },
    },

    manufacturerRoot: {
      borderRight: '2px #282828 solid',
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },

    rangeRoot: {
      borderRight: '2px #282828 solid',
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },

    manufacturer: {
      margin: '50px 0 0',
      padding: 0,
      listStyle: 'none',
      minHeight: 500,
      fontFamily: styleConfig.fonts.header,
      position: 'relative',
      willChange: 'transform',

      '& > li': {
        fontWeight: 500,
        display: 'block',
        color: rgba('#FFF', 0.6),
        transition: '333ms ease-in-out all',
        position: 'relative',

        '& .anchor': {
          '& svg': {
            opacity: 0,
            transform: 'translate3d(-50px, 0, 0)',
            willChange: 'transform',
          },

          '&:hover': {
            '& svg': {
              opacity: 1,
              fill: styleConfig.color.text.white,
              transform: 'translate3d(-25px, 0, 0)',
              willChange: 'transform',
            },
          },
        },

        '&.active': {
          color: styleConfig.color.text.gold,

          '&:before': {
            position: 'absolute',
            top: -50,
            right: 0,
            content: "''",
            height: 150,
            width: 30,
            zIndex: 900,
          },
        },

        '& svg': {
          transform: 'translate3d(-50px, 0, 0)',
          willChange: 'transform',
          transition: '0.3s ease-in-out transform',
        },

        '&:hover': {
          color: styleConfig.color.text.white,
          backgroundColor: styleConfig.color.bg.gold,

          '& svg': {
            opacity: 1,
            fill: styleConfig.color.text.white,
            transform: 'translate3d(-25px, 0, 0)',
            willChange: 'transform',
          },
        },
        '& > a': {
          width: '100%',
          padding: '15.5px 15px 15.5px 15.5px',
          fontSize: 21,
          display: 'block',
          lineHeight: 1,

          [theme.breakpoints.down('lg')]: {
            fontSize: 18,
          },
        },
      },
    },

    range: {
      margin: 0,
      padding: '50px 0',
      listStyle: 'none',
      backgroundColor: '#151515',
      fontFamily: styleConfig.fonts.header,
      overflowY: 'scroll',
      height: 850,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: '100%',
      willChange: 'transform',

      [theme.breakpoints.down('lg')]: {
        maxHeight: 'calc(90vh - 105px)',
      },

      '& > li': {
        fontWeight: 500,
        display: 'block',

        color: rgba('#FFF', 0.6),
        transition: '333ms ease-in-out all',
        position: 'relative',

        '&.active': {
          color: styleConfig.color.text.gold,

          '&:before': {
            position: 'absolute',
            top: -50,
            right: 0,
            content: "''",
            height: 150,
            width: 30,
            zIndex: 900,
          },
        },

        '& svg': {
          transform: 'translate3d(-50px, 0, 0)',
          willChange: 'transform',
          transition: '0.3s ease-in-out transform',
        },

        '&:hover': {
          color: styleConfig.color.text.white,
          backgroundColor: styleConfig.color.bg.gold,
          '& svg': {
            transform: 'translate3d(-25px, 0, 0)',
            willChange: 'transform',
            transition: '0.3s ease-in-out all',
            fill: styleConfig.color.text.white,
          },
        },

        '&.anchor': {
          '& svg': {
            opacity: 0,
            transform: 'translate3d(-50px, 0, 0)',
            willChange: 'transform, opacity',
            fill: styleConfig.color.text.gold,
          },

          '&:hover': {
            '& svg': {
              opacity: 1,
              transform: 'translate3d(-25px, 0, 0)',
              willChange: 'transform, opacity',
              transition: '0.3s ease-in-out all',
              fill: styleConfig.color.text.white,
            },
          },

          '& > a': {
            lineHeight: 1,
            width: '100%',
            display: 'block',
            padding: '11.5px 15px 11.5px 11.5px',
            fontSize: 18,

            [theme.breakpoints.down('lg')]: {
              fontSize: 16,
            },
          },
        },
      },
    },

    model: {
      margin: 0,
      top: 0,
      padding: '50px 0',
      backgroundColor: 'rgba(16, 16, 16, 0.85)',
      listStyle: 'none',
      height: 850,
      zIndex: 500,
      fontFamily: styleConfig.fonts.header,
      position: 'absolute',
      width: '25%',
      overflowY: 'scroll',
      maxHeight: '100%',
      opacity: modelItems.length ? 1 : 0,
      willChange: 'transform, opacity',

      '& > li': {
        '& > a': {
          lineHeight: 1,
          width: '100%',
          display: 'block',
          padding: '15.5px 15px 15.5px 15.5px',
          fontSize: 21,
          color: rgba('#FFF', 0.6),
          transition: '333ms ease-in-out all',

          [theme.breakpoints.down('lg')]: {
            fontSize: 18,
          },

          '& svg': {
            transform: 'translate3d(-50px, 0, 0)',
            willChange: 'transform, opacity',
            transition: '0.3s ease-in-out transform',
            opacity: 0,
          },

          '&.active': {
            color: styleConfig.color.text.gold,
          },
          '&:hover': {
            color: styleConfig.color.text.white,
            backgroundColor: styleConfig.color.bg.gold,
            '& svg': {
              transform: 'translate3d(-25px, 0, 0)',
              willChange: 'transform, opacity',
              transition: '0.3s ease-in-out all',
              fill: styleConfig.color.text.white,
              opacity: 1,
            },
          },
        },
      },
    },

    modelImage: {
      width: '100%',
      zIndex: 450,
      height: 852,

      [theme.breakpoints.down('lg')]: {
        minHeight: 502,
        maxHeight: 'calc(90vh - 105px)',
      },
    },
  }));

  const classes = useStyles();

  /**
   * Get the initial menu items and set the model and ranges of the first menu item
   */
  const handleInitialMenu = () => {
    if (data && data.manufacturers && data.manufacturers.edges[0]) {
      // The first item in the menu (by WPID)
      const firstItem =
        data &&
        data.manufacturers &&
        data.manufacturers.edges[0] &&
        data.manufacturers.edges[0].node &&
        data.manufacturers.edges[0].node.wordpress_id;

      const menuItems = data.submenu.edges.filter(
        (item) => item.node.wordpress_parent === firstItem
      );

      const publishedItems = menuItems.filter(
        (item) =>
          (!isNull(item.node.acf) &&
            !isNull(item.node.acf.brand_page) &&
            item.node.acf.brand_page) ||
          item.node.brand_meta_fields.hasChildren
      );

      const firstRangeItem =
        publishedItems[0] &&
        !isUndefined(publishedItems[0].node) &&
        publishedItems[0].node.wordpress_id;

      // Set the default
      if (publishedItems && publishedItems[0] && publishedItems[0].node) {
        const subMenuItems = data.submenu.edges.filter((item) => {
          if (
            item.node.wordpress_parent === publishedItems[0].node.wordpress_id &&
            !isNull(item.node.acf) &&
            !isNull(item.node.acf.brand_page) &&
            item.node.acf.brand_page
          ) {
            return item;
          }

          return null;
        });

        const rangeImage = get(
          publishedItems,
          '[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData',
          placeholder
        );

        setRangeItems(publishedItems);
        setActiveManufacturer(firstItem);
        setActiveRange(firstRangeItem);
        setModelImage(rangeImage);
        setModelItems(subMenuItems);

        if (subMenuItems[0] && subMenuItems[0].node) {
          // Set the active model if it exists
          setActiveModel(subMenuItems[0].node.wordpress_id);

          // Set the active image if it exits

          const subMenuImage = get(
            subMenuItems,
            '[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData',
            placeholder
          );
          if (subMenuImage) {
            return setModelImage(
              subMenuItems[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData
            );
          }
        }
      }
    }
  };

  const handleChildMenuItems = debounce(
    (id, type, delay) => {
      // Update the debounce time depending on if it is mobile menu so we can re-use this function
      debounceTimeout = delay;

      // Don't reset the selection if we are already on the selected manufacturer
      if (type === 'manufacturer' && id === activeManufacturer && !isMobileBrandMenuOpen) {
        return;
      }

      const menuItems = data.submenu.edges.filter((item) => item.node.wordpress_parent === id);
      const currentSelection = data.submenu.edges.filter((item) => item.node.wordpress_id === id);

      const publishedItems = menuItems.filter(
        (item) =>
          (!isNull(item.node.acf) &&
            !isNull(item.node.acf.brand_page) &&
            item.node.acf.brand_page) ||
          item.node.brand_meta_fields.hasChildren
      );

      const rangeImage = get(
        publishedItems,
        '[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData',
        placeholder
      );

      if (type === 'manufacturer') {
        // Reset the model view
        setModelItems([]);

        // Set the default
        if (publishedItems && publishedItems[0] && publishedItems[0].node) {
          // Get all the submenu items that have the parent ID
          const subMenuItems = data.submenu.edges.filter(
            (item) => item.node.wordpress_parent === publishedItems[0].node.wordpress_id
          );

          const publishedSubMenuItems = subMenuItems.filter(
            (item) =>
              item.node && item.node.acf && item.node.acf.brand_page && item.node.acf.brand_page
          );

          const firstSubmenuItem =
            publishedItems &&
            publishedItems[0] &&
            publishedItems[0].node &&
            !isNull(publishedItems[0].node.wordpress_id)
              ? publishedItems[0].node.wordpress_id
              : 0;

          setRangeItems(publishedItems);
          setActiveManufacturer(id);
          setActiveRange(firstSubmenuItem);
          setModelItems(publishedSubMenuItems);

          // Set the active model and image for the range or the model

          // Check if its a model
          if (publishedSubMenuItems[0] && publishedSubMenuItems[0].node) {
            // Set the active model if it exists

            setActiveModel(publishedSubMenuItems[0].node.wordpress_id);

            if (publishedSubMenuItems[0].node.acf && publishedSubMenuItems[0].node.acf.menu_image) {
              // Set the active image if it exits
              if (
                !isNull(
                  publishedSubMenuItems[0].node.acf.menu_image.localFile.childImageSharp
                    .gatsbyImageData
                )
              ) {
                setModelImage(
                  publishedSubMenuItems[0].node.acf.menu_image.localFile.childImageSharp
                    .gatsbyImageData || placeholder
                );
              }
            } else {
              setModelImage(rangeImage || placeholder);
            }

            // Otherwise if no default model look for a default range
          } else if (publishedItems[0] && publishedItems[0].node) {
            // Set the active model if it exists
            setActiveModel(publishedItems[0].node.wordpress_id);

            if (publishedItems[0].node.acf && publishedItems[0].node.acf.menu_image) {
              // Set the active image if it exits
              if (
                !isNull(
                  publishedItems[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData
                )
              ) {
                setModelImage(
                  publishedItems[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData ||
                    placeholder
                );
              }
            } else {
              setModelImage(rangeImage || placeholder);
            }

            // Add the fallback image if nothing found
          } else {
            setModelImage(placeholder);
          }
        }

        // Set the active ranges
        return setRangeItems(publishedItems);
      }

      if (type === 'range') {
        setActiveModel([]);

        setActiveRange(id);

        const rangeImage = get(
          currentSelection,
          '[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData',
          placeholder
        );

        if (publishedItems[0] && publishedItems[0].node) {
          // Set the active model if it exists
          setActiveModel(publishedItems[0].node.wordpress_id);

          if (publishedItems[0].node.acf && publishedItems[0].node.acf.menu_image) {
            // Set the active image if it exits
            if (
              !isNull(
                publishedItems[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData
              )
            ) {
              setModelImage(rangeImage);
            }
          } else {
            setModelImage(placeholder);
          }
        } else if (
          currentSelection[0] &&
          currentSelection[0].node &&
          currentSelection[0].node.acf &&
          currentSelection[0].node.acf.menu_image
        ) {
          setModelImage(rangeImage);
        } else {
          setModelImage(placeholder);
        }

        // Set the active models
        return setModelItems(publishedItems);
      }

      if (type === 'model') {
        if (!isEmpty(currentSelection) && !isNull(currentSelection)) {
          // Set the active model
          setActiveModel(currentSelection[0].node.wordpress_id);

          if (
            currentSelection[0] &&
            currentSelection[0].node &&
            currentSelection[0].node.acf &&
            currentSelection[0].node.acf.menu_image
          ) {
            if (
              !isNull(
                currentSelection[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData
              )
            ) {
              return setModelImage(
                currentSelection[0].node.acf.menu_image.localFile.childImageSharp.gatsbyImageData ||
                  placeholder
              );
            }
          }
        }

        return setModelImage(placeholder);
      }
    },
    debounceTimeout ? 400 : 50
  );

  const handleMenuOpen = () => {
    if (isOpen) {
      return setIsOpen(false);
    }

    handleInitialMenu();
    setIsOpen(true);
  };

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const handleMobileBrandMenuOpen = () => {
    setIsMobileBrandMenuOpen(true);
  };

  const handleMobileBrandMenuClose = () => {
    setIsMobileBrandMenuOpen(false);
  };

  const handleMobileBack = () => {
    // Close the menu and reset the menu
    setRangeItems([]);
    setMobileStep(1);
  };

  const MobileManufacuters = () => {
    return (
      <React.Fragment>
        {data.manufacturers.edges && data.manufacturers.edges.length > 1 && (
          <Slide direction="right" in={mobileStep === 1}>
            <div className={classes.mobileMenuBrand}>
              <Typography className={classes.mobileMenuTitle}>Brands</Typography>
              {data.manufacturers.edges.map((item) => {
                return (
                  <li key={item.node.id}>
                    {!isNull(item.node.acf) && !isNull(item.node.acf.brand_page) ? (
                      <React.Fragment>
                        <Link
                          aria-label={
                            (item.node.acf &&
                              item.node.acf.menu_title &&
                              item.node.acf.menu_title) ||
                            item.node.name
                          }
                          to={`/${item.node.brand_meta_fields.slug}/`}
                        >
                          {(item.node.acf &&
                            item.node.acf.menu_title &&
                            item.node.acf.menu_title) ||
                            item.node.name}{' '}
                          Leasing
                        </Link>

                        <div>
                          <Button
                            aria-label="View ranges"
                            className={classes.mobileChevron}
                            onClick={() => {
                              handleChildMenuItems(item.node.wordpress_id, 'manufacturer');
                              setMobileStep(2);
                            }}
                          >
                            {item.node.brand_meta_fields.hasChildren && (
                              <span>
                                <ChevronRightIcon color="primary" fontSize="large" />
                              </span>
                            )}
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <span>
                        {item.node.name} leasing{' '}
                        {item.node.brand_meta_fields.hasChildren && (
                          <div>
                            <Button
                              aria-label="View"
                              className={classes.mobileChevron}
                              onClick={() => {
                                handleChildMenuItems(item.node.wordpress_id, 'manufacturer');
                                setMobileStep(2);
                              }}
                            >
                              <span>
                                <ChevronRightIcon color="primary" fontSize="large" />
                              </span>
                            </Button>
                          </div>
                        )}
                      </span>
                    )}
                  </li>
                );
              })}
            </div>
          </Slide>
        )}
      </React.Fragment>
    );
  };

  const MobileRanges = () => {
    return (
      <React.Fragment>
        {rangeItems && rangeItems.length >= 1 && (
          <Slide direction="left" in={mobileStep === 2}>
            <div className={classes.mobileMenuBrand}>
              <Typography>
                <Button
                  aria-label="Go back"
                  className={classes.mobileBackBtn}
                  onClick={() => handleMobileBack()}
                >
                  <ChevronRightIcon fontSize="large" /> Back
                </Button>
              </Typography>

              {rangeItems.map((item) => (
                <li key={item.node.id}>
                  {!isNull(item.node.acf) && !isNull(item.node.acf.brand_page) ? (
                    <React.Fragment>
                      <Link
                        aria-label={
                          (item.acf && item.acf.menu_title && item.acf.menu_title) || item.node.name
                        }
                        to={`/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`}
                      >
                        {(item.acf && item.acf.menu_title && item.acf.menu_title) || item.node.name}{' '}
                      </Link>

                      {item.node.brand_meta_fields.hasChildren && (
                        <div>
                          <Button
                            aria-label="View models"
                            className={classes.mobileChevron}
                            onClick={() => {
                              handleChildMenuItems(item.node.wordpress_id, 'range', false);
                              setMobileStep(3);
                            }}
                          >
                            <ChevronRightIcon color="primary" fontSize="large" />
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span>{item.node.name}</span>

                      {item.node.brand_meta_fields.hasChildren && (
                        <div>
                          <Button
                            aria-label="View ranges"
                            className={classes.mobileChevron}
                            onClick={() => {
                              handleChildMenuItems(item.node.wordpress_id, 'range', false);
                              setMobileStep(3);
                            }}
                          >
                            <ChevronRightIcon className={classes.mobileChevron} color="primary" />
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </li>
              ))}
            </div>
          </Slide>
        )}
      </React.Fragment>
    );
  };

  const MobileModels = () => {
    return (
      <React.Fragment>
        {modelItems && modelItems.length >= 1 && (
          <Slide direction="left" in={mobileStep === 3}>
            <div className={classes.mobileMenuBrand}>
              <Typography>
                <Button
                  aria-label="Go back"
                  className={classes.mobileBackBtn}
                  onClick={() => handleMobileBack()}
                >
                  <ChevronRightIcon fontSize="large" /> Back
                </Button>
              </Typography>

              {modelItems.map((item) => {
                return (
                  <li
                    key={item.node.id}
                    onMouseEnter={() => {
                      if (item.node.brand_meta_fields.hasChildren) {
                        handleChildMenuItems(item.node.wordpress_id, 'model', false);
                      }
                    }}
                  >
                    <Link
                      aria-label={
                        (item.acf && item.acf.menu_title && item.acf.menu_title) || item.node.name
                      }
                      className={activeModel === item.node.wordpress_id ? 'active' : ''}
                      to={`/${item.node.parent_element.parent_element.brand_meta_fields.slug}/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`}
                    >
                      {(item.acf && item.acf.menu_title && item.acf.menu_title) || item.node.name}{' '}
                    </Link>
                  </li>
                );
              })}
            </div>
          </Slide>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <header className={clsx('header-primary', classes.wrapper)}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xl={4} lg={5} md={3} sm={5} xs={5}>
            <Box className={classes.headerLeft}>
              <Link aria-label="Morgan Highfield &amp; Land" to="/">
                <img
                  alt={siteConfig.siteTitle}
                  src={data.logo.publicURL}
                  className={classes.logo}
                  width={182}
                  height={70}
                />
              </Link>

              <Hidden mdDown xlDown={iOSDevice}>
                <ul className={classes.menuRoot}>
                  <li>
                    <span onMouseEnter={() => handleMenuOpen()} onClick={() => handleMenuOpen()}>
                      Brands
                      <div className={classes.arrow}>
                        <KeyboarArrowDownIcon />
                      </div>
                    </span>
                  </li>

                  <li className="primary">
                    <Link aria-label="Special offers" to="/special-offers/">
                      Special Offers
                    </Link>
                  </li>
                </ul>
              </Hidden>
            </Box>
          </Grid>

          {/* Mobile Menu */}
          <Hidden lgUp>
            <Grid item md={9} sm={7} xs={7}>
              <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
                <Grid item sm={4} xs={3}>
                  <Box>
                    <Hidden lgUp={!iOSDevice}>
                      <ul className={clsx(classes.menuRoot, classes.mobileHeaderRight)}>
                        {!isMobileBrandMenuOpen && !isMobileMenuOpen ? (
                          <React.Fragment>
                            <li>
                              <SearchBar data={searchData} />
                            </li>
                            <li key={uniqueId()}>
                              <Button
                                onClick={() => handleMobileBrandMenuOpen()}
                                className={clsx('primary', classes.mobileButton)}
                                variant="text"
                                name="mobile-menu-brands"
                                aria-label="Mobile Menu Brands"
                              >
                                Brands
                              </Button>
                            </li>
                            <li key={uniqueId()}>
                              <Button
                                aria-label="Mobile Menu"
                                variant="text"
                                className={classes.mobileButton}
                                onClick={() => handleMobileMenuOpen()}
                                name="mobile-menu"
                              >
                                <MenuIcon fontSize="large" />
                              </Button>
                            </li>
                          </React.Fragment>
                        ) : (
                          <li>
                            <Button
                              aria-label="Close Mobile Menu"
                              variant="text"
                              className={classes.mobileButton}
                              name="close-mobile-menu"
                              onClick={() => {
                                // Close the menu and reset the menu
                                setRangeItems([]);

                                if (isMobileMenuOpen) {
                                  return handleMobileMenuClose();
                                }

                                setMobileStep(1);

                                return handleMobileBrandMenuClose();
                              }}
                            >
                              <CloseIcon className={classes.closeIcon} />
                            </Button>
                          </li>
                        )}
                      </ul>
                    </Hidden>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden mdDown>
            <Grid item xl={8} lg={7}>
              <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
                <Grid item xl={4} lg={5}>
                  <SearchBar data={searchData} />
                </Grid>

                <Grid item xl={3} lg={5}>
                  <Box className={classes.headerRight}>
                    <ul className={clsx([classes.menuRoot, classes.menuSecondary])}>
                      {data.secondaryNav.items.map((item) => (
                        <li className={item.attr === 'primary' ? 'primary' : ''} key={uniqueId()}>
                          <Link
                            aria-label={item.title}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                            key="header-title"
                            to={`/${item.object_slug}/`}
                          />
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        {/* Mega Menu */}
        <Hidden mdDown={!iOSDevice}>
          <Fade
            in={isOpen}
            // in
          >
            <Box className={classes.megaMenuRoot} onMouseLeave={() => setIsOpen(false)}>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} className={classes.manufacturerRoot}>
                  <Box component="ul" className={classes.manufacturer}>
                    {data.manufacturers.edges.map((item) => {
                      return (
                        <li
                          key={item.node.id}
                          className={clsx(
                            'anchor',
                            activeManufacturer === item.node.wordpress_id ? 'active' : ''
                          )}
                        >
                          {item.node.brand_meta_fields.hasChildren &&
                          !isNull(item.node.acf.brand_page) ? (
                            <Fade in timeout={500}>
                              <Link
                                aria-label={
                                  (item.node.acf &&
                                    item.node.acf.menu_title &&
                                    item.node.acf.menu_title) ||
                                  item.node.name
                                }
                                to={`/${item.node.brand_meta_fields.slug}/`}
                                onMouseEnter={() => {
                                  if (item.node.brand_meta_fields.hasChildren) {
                                    handleChildMenuItems(
                                      item.node.wordpress_id,
                                      'manufacturer',
                                      true
                                    );
                                  }
                                }}
                              >
                                {(item.node.acf &&
                                  item.node.acf.menu_title &&
                                  item.node.acf.menu_title) ||
                                  item.node.name}{' '}
                                Leasing
                                <div className={classes.arrow}>
                                  {item.node.brand_meta_fields.hasChildren ? (
                                    <ArrowRightAltIcon color="primary" />
                                  ) : (
                                    <ChevronRightIcon className={classes.arrow} />
                                  )}
                                </div>
                              </Link>
                            </Fade>
                          ) : (
                            <Fade in timeout={500}>
                              <a
                                href="#"
                                onMouseEnter={() => {
                                  if (item.node.brand_meta_fields.hasChildren) {
                                    handleChildMenuItems(item.node.wordpress_id, 'manufacturer');
                                  }
                                }}
                              >
                                {item.node.name} leasing{' '}
                                <div className={classes.arrow}>
                                  <ArrowRightAltIcon color="primary" />
                                </div>
                              </a>
                            </Fade>
                          )}
                        </li>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid item xl={3} lg={3} md={4} className={classes.rangeRoot}>
                  <Box component="ul" className={clsx('scroll-menu', classes.range)}>
                    {rangeItems.map((item) => (
                      <li
                        key={item.node.id}
                        className={clsx(
                          'anchor',
                          activeRange === item.node.wordpress_id ? 'active' : ''
                        )}
                        onMouseEnter={() => {
                          handleChildMenuItems(item.node.wordpress_id, 'range', true);
                        }}
                      >
                        {!isNull(item.node.acf) && !isNull(item.node.acf.brand_page) ? (
                          <Fade in timeout={500}>
                            <Link
                              aria-label={
                                (item.acf && item.acf.menu_title && item.acf.menu_title) ||
                                item.node.name
                              }
                              to={`/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`}
                            >
                              {(item.acf && item.acf.menu_title && item.acf.menu_title) ||
                                item.node.name}{' '}
                              <div className={classes.arrow}>
                                {item.node.brand_meta_fields.hasChildren ? (
                                  <ChevronRightIcon className={classes.arrow} />
                                ) : (
                                  <ArrowRightAltIcon color="primary" />
                                )}
                              </div>
                            </Link>
                          </Fade>
                        ) : (
                          <React.Fragment>
                            <Fade in timeout={500}>
                              <a
                                href="#"
                                className={activeRange === item.node.wordpress_id ? 'active' : ''}
                              >
                                {item.node.name}
                                <div className={classes.arrow}>
                                  <ChevronRightIcon className={classes.arrow} />
                                </div>
                              </a>
                            </Fade>
                          </React.Fragment>
                        )}
                      </li>
                    ))}
                  </Box>
                </Grid>
                <Grid item xl={5} lg={5} md={4}>
                  <GatsbyImage
                    image={modelImage}
                    loading="eager"
                    className={classes.modelImage}
                    alt="Model image"
                  />
                  <Box component="ul" className={clsx(['scroll-menu', classes.model])}>
                    {modelItems.map((item) => {
                      return (
                        <li
                          key={item.node.id}
                          onMouseEnter={() => {
                            if (item.node.brand_meta_fields.hasChildren) {
                              handleChildMenuItems(item.node.wordpress_id, 'model', true);
                            }
                          }}
                        >
                          <Fade in timeout={500}>
                            <Link
                              aria-label={
                                (item.acf && item.acf.menu_title && item.acf.menu_title) ||
                                item.node.name
                              }
                              className={activeModel === item.node.wordpress_id ? 'active' : ''}
                              to={`/${item.node.parent_element.parent_element.brand_meta_fields.slug}/${item.node.parent_element.brand_meta_fields.slug}/${item.node.brand_meta_fields.slug}/`}
                            >
                              {(item.acf && item.acf.menu_title && item.acf.menu_title) ||
                                item.node.name}{' '}
                              <div className={classes.arrow}>
                                <ArrowRightAltIcon color="primary" />
                              </div>
                            </Link>
                          </Fade>
                        </li>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Hidden>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && <MobileMenu isOpen={isMobileMenuOpen} />}

      {/* Mobile Brand Menu */}
      {isMobileBrandMenuOpen && (
        <MobileMenuBrands isOpen={isMobileBrandMenuOpen}>
          {mobileStep === 1 && <MobileManufacuters />}
          {mobileStep === 2 && <MobileRanges />}
          {mobileStep === 3 && <MobileModels />}
        </MobileMenuBrands>
      )}
    </React.Fragment>
  );
};

export default Header;
