import React from 'react';
import { Helmet } from 'react-helmet';
import { lighten } from 'polished';
import { Box, Fade, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { styleConfig } from '../../config/config';
import RouteLink from './RouteLink';

const MobileMenu: React.FunctionComponent<MobileMenuProps> = (props) => {
  const { isOpen } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      background: lighten(0.075, styleConfig.color.bg.black),
      top: 0,
      zIndex: 970,
      borderTop: '1px #444 solid',
      paddingTop: 102,
      overflow: 'hidden',
    },

    mobileMenuTitle: {
      color: '#444',
      fontSize: 20,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginBottom: 10,
      padding: '0 50px',

      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },
    },

    menuRoot: {
      margin: '50px 0',
      padding: 0,
      listStyle: 'none',
      minHeight: 500,
      fontFamily: styleConfig.fonts.header,
      position: 'relative',
      willChange: 'transform, opacity',

      [theme.breakpoints.down('md')]: {
        margin: '15px 0 0',
      },

      '& > li': {
        fontWeight: 600,
        display: 'block',
        color: '#FFF',
        transition: '333ms ease-in-out all',
        position: 'relative',

        '& .anchor': {
          '& svg': {
            opacity: 0,
            transform: 'translate3d(-50px, 0, 0)',
            willChange: 'transform, opacity',
          },

          '&:hover': {
            '& svg': {
              opacity: 1,
              fill: styleConfig.color.text.white,
            },
          },
        },

        '&.active': {
          color: styleConfig.color.text.gold,

          '&:before': {
            position: 'absolute',
            top: -50,
            right: 0,
            content: "''",
            height: 150,
            width: 30,
            zIndex: 900,
          },
        },

        '& svg': {
          transform: 'translate3d(-50px, 0, 0)',
          willChange: 'transform',
          transition: '0.3s ease-in-out transform',
        },

        '&:hover': {
          color: styleConfig.color.text.white,
          backgroundColor: styleConfig.color.bg.gold,

          '& svg': {
            opacity: 1,
            fill: styleConfig.color.text.white,
          },
        },
        '& > a': {
          width: '100%',
          padding: '22.5px 50px 22.5px 50px',
          fontSize: 21,
          display: 'block',

          [theme.breakpoints.down('md')]: {
            padding: '22.5px 50px 22.5px 50px',
          },

          [theme.breakpoints.down('sm')]: {
            padding: '20px',
            fontSize: 18,

            '& > span': {
              width: '85%',
              display: 'block',
            },
          },
        },
      },
    },

    arrow: {
      position: 'absolute',
      right: -20,
      top: 15,
    },

    nav: {
      height: '100%',
      overflowY: 'scroll',
    },
  }));

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      wordpressWpApiMenusMenusItems(name: { eq: "Mobile Menu" }) {
        items {
          wordpress_id
          title
          object_slug
          attr
          url
          object
        }
      }
    }
  `);

  return (
    <React.Fragment>
      {isOpen && (
        <Helmet
          bodyAttributes={{
            class: 'locked',
          }}
        />
      )}

      <Fade in={isOpen} timeout={{ enter: 350, exit: 200 }}>
        <Box className={clsx('mobile-menu', classes.root)}>
          <nav className={classes.nav}>
            <ul className={classes.menuRoot}>
              <Typography className={classes.mobileMenuTitle}>Menu</Typography>

              {data.wordpressWpApiMenusMenusItems.items.map((item, index) => {
                return (
                  <li key={`mobile-menu-item-${index}`}>
                    <RouteLink aria-label={item.title} key="header-title" href={item.url}>
                      <span dangerouslySetInnerHTML={{ __html: item.title }}></span>

                      <ArrowRightAltIcon
                        className={classes.arrow}
                        color="primary"
                        fontSize="large"
                      />
                    </RouteLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </Box>
      </Fade>
    </React.Fragment>
  );
};

interface MobileMenuProps {
  isOpen: boolean;
  handleMenu: Function;
}

export default MobileMenu;
